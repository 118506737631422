import * as React from "react";
import axios from "axios";
import { api } from "../../config";
import { graphql } from "gatsby";
import { format } from "date-fns";
import localize from "../../hoc/Localize";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Redirect from "../../components/Redirect";
import ConfirmButton from "../../components/unsubscribeButton/confirmButton";
import {
    isLoggedIn,
    getUser,
    getAccessToken,
    getUserData,
} from "../../utils/auth";
import Card from "./card";
import AccountLayout from "./account-layout";
import Payment from "./payment-page";
import MealplanIcon from "./mealplan-icon";
import FitplanIcon from "./fitplan-icon";
import Member from "../../components/Account/MembershipPanel";
import Mealplan from "./mealplan-page";

class Subscription extends React.Component {
    state = {
        user: getUser(),
        cancelSuccess: null,
        cancelFailure: null,
        showConfirm: false,
        confirm: {
            title: "",
            info: "",
            question: "",
            cancelButton: "",
            confirmButton: "",
        },
    };

    showConfirm = e => {
        this.setState({ confirm: e, showConfirm: !this.state.showConfirm });
    };

    updateStatus = e => {
        this.setState(
            {
                cancelSuccess: e.cancelSuccess,
                cancelFailure: e.cancelFailure,
            },
            // TODO: check if we need hide toast
            () => {
                setTimeout(
                    function() {
                        this.setState({
                            cancelSuccess: "",
                            cancelFailure: "",
                        });
                    }.bind(this),
                    3000
                );
            }
        );
    };

    handleCancelClick = e => {
        const { account } = this.props.data.sanityAccount;
        if (this.state.user.paymentStoreType == 3) {
            axios({
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
                url: `${api.base}${api.cancelStripe}`,
            })
                .then(res => {
                    if (res.data.result) {
                        getUserData(getAccessToken()).then(() => {
                            this.setState({
                                cancelSuccess: account.cancelSuccess,
                                cancelFailure: "",
                                showConfirm: false,
                                user: Object.assign({}, getUser(), {
                                    cancelAtPeriodEnd: true,
                                }),
                            });
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        showConfirm: false,
                        cancelSuccess: "",
                        cancelFailure: account.cancelFailure,
                    });
                });
        }
    };

    render() {
        // const { error, success } = this.state;
        const { account } = this.props.data.sanityAccount;
        let hideButton = false;
        if (
            this.state.user &&
            this.state.user.giftCards &&
            this.state.user.giftCards.length > 0
        ) {
            hideButton = true;
        }
        if (
            this.state.user &&
            this.state.user.oneTimeProducts &&
            this.state.user.oneTimeProducts.length > 0
        ) {
            hideButton = true;
        }

        return isLoggedIn() ? (
            <PageWrapper>
                <AccountLayout currentPage="/account/subscription">
                    {this.state.showConfirm ? (
                        <Card className="wide_card">
                            <ConfirmButton
                                onCancel={this.showConfirm}
                                onConfirm={this.handleCancelClick}
                                cancelText={this.state.confirm.cancelText}
                                confirmText={this.state.confirm.confirmText}
                                title={this.state.confirm.title}
                                url={
                                    this.state.user.paymentStoreType === 3
                                        ? ""
                                        : this.state.user.paymentStoreType === 2
                                        ? account.subscriptionCancelButton
                                              .unsubscribeGoogleCode
                                        : account.subscriptionCancelButton
                                              .unsubscribeAppleCode
                                }
                                info={
                                    this.state.confirm.info +
                                    " " +
                                    (this.state.user.displayExpirationTimeStamp
                                        ? format(
                                              new Date(
                                                  this.state.user.displayExpirationTimeStamp
                                              ),
                                              "MMMM do, yyyy"
                                          )
                                        : "")
                                }
                                question={this.state.confirm.question}
                                cancelButton={
                                    account.subscriptionCancelButton
                                        .cancelButton
                                }
                                confirmButton={
                                    account.subscriptionCancelButton
                                        .confirmButton
                                }
                            />
                        </Card>
                    ) : (
                        <>
                            <Member
                                titleText="Fitplan"
                                SVGIcon={<FitplanIcon width="40px" />}
                                user={this.state.user}
                                profile={account}
                                handleCancelClick={this.handleCancelClick}
                                onConfirm={this.handleCancelClick}
                                hideButton={hideButton}
                                showConfirm={this.showConfirm}
                            />
                            {false && (
                                <Mealplan
                                    titleText="Mealplan"
                                    SVGIcon={
                                        <MealplanIcon
                                            width={24}
                                            viewBox="0 0 24 24"
                                        />
                                    }
                                    user={this.state.user}
                                    profile={account}
                                    handleCancelClick={this.handleCancelClick}
                                    onConfirm={this.handleCancelClick}
                                    showConfirm={this.showConfirm}
                                />
                            )}
                            {this.state.user.paymentStoreType !== 0 &&
                            this.state.user.paymentExpirationTimestamp !== 0 ? (
                                <Payment
                                    profile={account}
                                    updateStatus={this.updateStatus}
                                />
                            ) : (
                                ""
                            )}
                        </>
                    )}
                </AccountLayout>
            </PageWrapper>
        ) : (
            <Redirect to="/login" />
        );
    }
}

export const query = graphql`
    query subscriptionQuery {
        sanityAccount {
            account {
                member {
                    _type
                    en
                    es
                }
                nonMember {
                    _type
                    en
                    es
                }
                joinFitplan {
                    _type
                    en
                    es
                }
                joinFitplanMessage {
                    _type
                    en
                    es
                }
                overview {
                    _type
                    en
                    es
                }
                mealMember {
                    _type
                    en
                    es
                }
                nonMealMember {
                    _type
                    en
                    es
                }
                joinMeal {
                    _type
                    en
                    es
                }
                joinMealMessage {
                    _type
                    en
                    es
                }
                cancelSuccess {
                    _type
                    en
                    es
                }
                cancelFailure {
                    _type
                    en
                    es
                }
                updatePaymentMethod {
                    _type
                    en
                    es
                }
                updatePaymentFail {
                    _type
                    en
                    es
                }
                updatePaymentSuccess {
                    _type
                    en
                    es
                }
                paymentMethod {
                    _type
                    en
                    es
                }
                currentSubscription {
                    _type
                    en
                    es
                }
                subscriptionTitle {
                    _type
                    en
                    es
                }
                subscriptionCancelButton {
                    buttonText {
                        _type
                        en
                        es
                    }
                    unsubscribeAppleCode
                    unsubscribeGoogleCode
                    title {
                        _type
                        en
                        es
                    }
                    info {
                        _type
                        en
                        es
                    }
                    question {
                        _type
                        en
                        es
                    }
                    cancelButton {
                        _type
                        en
                        es
                    }
                    confirmButton {
                        _type
                        en
                        es
                    }
                }
                changePaymentDetails {
                    _type
                    en
                    es
                }
                enterCreditCard {
                    _type
                    en
                    es
                }
            }
        }
    }
`;

export default localize(Subscription);
