import * as React from 'react';
import PropTypes from 'prop-types';
import { css } from "@emotion/core";
import {Link} from "@fitplan/lib/components/Link";

const ConfirmButton = ({ ...props }) => (
  <div
    css={css`
      /* white-space: nowrap; */
      display: block;
      text-align: center;
      font-family: league-gothic, sans-serif;
      font-size: 20px;
      letter-spacing: normal;
      margin: 0 20px;
    `}
  >
    <div
      css={css`
        height: 40px;
        font-family: Barlow;
        font-size: 32px;
        font-weight: bold;
        font-style: normal;
        font-stretch: condensed;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        padding-bottom: 40px;
      `}
    >
      {props.title}
    </div>
    <div
      css={css`
        min-height: 56px;
        font-family: Barlow;
        font-size: 21px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        padding-bottom: 40px;
      `}
    >
      {props.info}
      <br />
      {props.question}
    </div>
    <div
      css={css`
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding-bottom: 40px;
        @media (max-width: 767px) {
          flex-direction: column;
        }
      `}
    >
      <button
        type="button"
        css={css`
          width: 120px;
          font-family: Barlow;
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.17;
          letter-spacing: normal;
          color: #000;
          border: none;
          background: transparent;
          height: 64px;
          &:hover {
            cursor: pointer;
          }
          @media (max-width: 767px) {
            order: 2;
            width: 100%;
          }
        `}
        onClick={props.onCancel}
      >
        {props.cancelButton}
      </button>
      {props.url == '' ? (
        <button
          type="button"
          css={css`
            width: 181px;
            height: 63.6px;
            border-radius: 100px;
            box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
            background-image: linear-gradient(251deg, #f10364, #fc172f);
            font-family: Barlow;
            font-size: 21px;
            font-weight: bold;
            font-style: normal;
            font-stretch: condensed;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            border: none;
            &:hover {
              cursor: pointer;
            }
            @media (max-width: 767px) {
              order: 1;
              width: 100%;
            }
          `}
          onClick={props.onConfirm}
        >
          {props.confirmButton}
        </button>
      ) : (
        <Link
          to={props.url}
          css={css`
            width: 181px;
            height: 63.6px;
            border-radius: 100px;
            box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
            background-image: linear-gradient(251deg, #f10364, #fc172f);
            font-family: Barlow;
            font-size: 21px;
            font-weight: bold;
            font-style: normal;
            font-stretch: condensed;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            border: none;
            display: table;
            &:hover {
              cursor: pointer;
            }
            @media (max-width: 767px) {
              order: 1;
              width: 100%;
            }
          `}
        >
          <span
            css={css`
              display: table-cell;
              vertical-align: middle;
            `}
          >
            {props.confirmButton}
          </span>
        </Link>
      )}
    </div>
  </div>
);

ConfirmButton.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
};

ConfirmButton.defaultProps = {
  onCancel: () => null,
  onConfirm: () => null,
  title: 'Are you sure?',
  url: '',
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};

export default ConfirmButton;
